@import "../../../mixins";

.complex-card {
  --complex-card-color: var(--bg-dark);

  padding: rem(50) rem(40);
  width: 100%;
  max-width: rem(454);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: rem(40);

  color: var(--text-light-primary);
  text-decoration: none;

  border-radius: var(--radius-common);
  background-color: var(--complex-card-color);
  overflow: hidden;

  @include mediaBigDesktop {
    padding: big(50) big(40);
    max-width: big(454);
    gap: big(40);
  }

  @include mediaDesktop {
    padding: rem(50) rem(30);
    max-width: rem(357.5);
    gap: rem(30);
  }

  @include mediaLaptop {
    padding: rem(35) rem(20);
    max-width: rem(262.5);
    gap: rem(20);

    border-radius: var(--radius-secondary);
  }

  @include mediaTablet {
    padding: rem(30) rem(20);
    max-width: rem(334);
  }

  @include mediaMobile {
    padding: rem(20) rem(15);
    max-width: rem(270);
    gap: rem(5);
  }

  @include hover {
    & .complex-card__title {
      color: #FFD53F;
    }
  }

  &__image {
    width: rem(150);
    height: rem(150);
    flex-shrink: 0;

    @include mediaBigDesktop {
      width: big(150);
      height: big(150);
    }

    @include mediaDesktop {
      width: rem(120);
      height: rem(120);
    }

    @include mediaLaptop {
      width: rem(80);
      height: rem(80);
    }

    @include mediaMobile {
      width: rem(56);
      height: rem(56);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaMobile {
      gap: rem(12);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-light-primary);
    font: 700 rem(34)/1.3 var(--font-primary);
    text-align: center;

    transition: color var(--animation-default);

    @include mediaBigDesktop {
      font-size: big(34);
    }

    @include mediaDesktop {
      font-size: rem(24);
    }

    @include mediaLaptop {
      font-size: rem(18);
    }

    @include mediaTablet {
      font: var(--font-card-title);
      letter-spacing: calc(-1em / 100);
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    & .price {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0;

      &__actual {
        color: #FFD53F;
        font: var(--font-card-title-L);

        @include mediaLaptop {
          font: 600 rem(24)/1.3 var(--font-primary);
          letter-spacing: calc(-1em / 100);
        }

        @include mediaMobile {
          font-size: rem(20);
        }
      }

      &__old {
        --system-red: var(--text-light-primary);

        color: var(--text-light-primary);
        font: 500 rem(24)/1.5 var(--font-primary);

        @include mediaBigDesktop {
          font-size: big(24);
        }

        @include mediaDesktop {
          font-size: rem(20);
        }

        @include mediaLaptop {
          font-size: rem(14);
        }
        
        &:before {
          background-color: var(--text-light-primary);
        }
      }
    }
  }

  & .tag {
    padding: rem(1) rem(15) rem(4);
    color: var(--text-light-primary);
    font: var(--font-body-M-m);

    background-color: rgba(255, 255, 255, 0.10);

    @include mediaBigDesktop {
      padding: big(1) big(15) big(4);
    }

    @include mediaLaptop {
      padding: 0 rem(10) rem(3);
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: rem(10);

      @include mediaBigDesktop {
        gap: big(10);
      }

      @include mediaLaptop {
        gap: rem(5);
      }
    }
  }
}
